@import "normalize.css";

@import "fonts";
// vars
@import "vars";

html{
  font-size: 62.5%;
}
html,body{
  height: 100%;
}
body{
  font-family: "basier", sans-serif;
  color: $grey;
}

h1{
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  margin: 0 0 2rem;
}

h1.highlight{
  background: $green;
  color: white;
  font-weight: bold;
  font-size: 3rem;
  display: inline;
  margin: 10rem 0 0;
  line-height: 1.25;
  box-shadow: 0.75rem 0 0 $green, -0.75rem 0 0 $green;
}
.wrapper{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.hide-desktop-flex{
  display: flex;
  @include md{
    display: none !important;
  }
}
.hide-mobile{
  display: none !important;
  @include md{
    display: block !important;
  }
}
.hide-desktop{
  display: block !important;
  @include md{
    display: none !important;
  }
}

@import "shared";
@import "header";
@import "cover";
@import "testimonials";
@import "home_content";
@import "contact";
@import "about";
@import "portfolio";
@import "post";
@import "footer";


