.footer{
  background: $primary;
  padding: $gutter-mobile;
  color: white;
  font-size: 1.8rem;
  line-height: 1.4;
  h2{
    margin: 0;
  }
  @include md{
    display: flex;
    padding: $gutter-desktop $gutter-desktop 8rem;
  }
  a{
    text-decoration: none;
    color: white;
    font-weight: 700;
    &.small{
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  p{
    margin: 1rem 0;
  }
  .left{
    flex: 1;
    margin-bottom: 2rem;
    @include md{
      margin-bottom: 0;
    }
    p{
      font-size: 1.6rem;
    }
    ul li a{
      font-size: 1.6rem;

    }
  }
  .right{
    flex: 1;
    @include md{
      text-align: right;
    }
  }
  .address{
    margin-bottom: 1rem;
  }
  .phone{
    margin-bottom: 0.5rem;
  }
  .email{
    margin-bottom: 1rem;
    display: block;
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    li{
      margin-bottom: 0.25rem;
      a{
        font-weight: 400;
      }

    }

  }
  //.octave-link{
  //  text-decoration:none;
  //  color: white;
  //  //border-bottom: 1px solid white;
  //  .octave{
  //    font-family: "Playfair Display", serif;
  //    font-weight: 700;
  //    text-transform: uppercase;
  //  }
  //}
}
