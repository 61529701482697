section#testimonials{
  padding: 5rem $gutter-mobile 0;
  @include md{
    padding: 5rem $gutter-desktop 0;
    .wrapper{
      display: flex;
    }
  }
}

.google-review{
  box-shadow: 2px 2px 10px 1px #eee;
  border-radius: 5px;
  padding: 2.5rem;
  margin: 0 0 2rem;
  .info{
    margin: 0 0 1rem;
    display: flex;
    .name-stars{
      flex: 1;
      .name{
        font-weight: 700;
        font-size: 2rem;
        margin: 0 0 1rem;
      }
      .stars{
        display: flex;
        align-items: center;
        width: 10rem;
        img{
          width: 100%;
        }
      }
    }
    .google-logo{
      width: 2rem;
      display: block;
      img{
        width: 100%;
      }
    }
  }
  .text{
    p{
      font-size: 1.8rem;
      margin: 0;
      line-height: 1.55;
    }
  }
}
@include md{
  .google-review{
    flex: 1;
    z-index: 2;
    margin: 0 2.5rem 0 0;
    &:last-child{
      margin-right: 0;
    }
    .info{
      .name-stars{
        display: flex;
        .name{
          margin: 0 1.5rem 0 0;
        }
      }
    }
    .text{
      padding-right: 3rem;
    }
  }
}
