#portfolio {
  padding: 5rem $gutter-mobile 0;
  .portfolio-covers{
    display: flex;
    flex-wrap: wrap;
  }
  .portfolio-cover{
    display: block;
    width: 100%;
    text-decoration: none;
    margin-bottom: 3rem;
    figure{
      height: 30rem;
      background-size: cover;
      background-position: center center;
      margin: 0;
      border-radius: 5px;
    }
    .caption{
      padding: 1rem 0 0;
      color: #222;
      .title{
        font-weight: 700;
        font-size: 1.6rem;
      }
      .subtitle{
        font-weight: 400;
        font-size: 1.5rem;

      }
    }
  }
  @include lg{
    padding: 10rem $gutter-desktop 0;
    .portfolio-covers{
      margin-left: -2rem;
      margin-right: -2rem;
    }
    .portfolio-cover{
      width: calc(33.3333% - 4rem);
      margin: 2rem;
    }
  }
}

