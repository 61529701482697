section#contact{
  padding: 5rem $gutter-mobile;
  background: white;
  >h2{
    text-align: left;
    font-size: 3rem;
    margin: 0 0 1.5rem;
    color: black;
  }
  >p{
    font-size: 2rem;
    margin: 0 0 3rem;
    text-align: left;
    line-height: 1.65;
  }
  .contact-links{
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    @include md{
      flex: 1;
      display: flex;
      justify-content: center;
      .cta{
        flex: 1;
        margin: 0 2rem;
        span{
          font-size: 2rem !important;
        }
      }
    }
    .cta{
      margin-bottom: 2rem;
      height: 6rem;
      border-radius: 3rem;
      span{
      }
    }
    .cta.email{
      span{
        font-size: 1.6rem;
      }
    }
    .contact-method{
      background: $dark-blue;
      color: white;
      padding: 2rem;
      border-radius: 0.5rem;
      display: flex;
      text-decoration: none;
      margin: 0 0 1rem;
      @include md{
        flex: 1;
        margin: 0 1em 1rem;
      }
      &:hover{
        background: $dark-blue-hover;
      }
      .text-info{
        flex: 1;
        p{
          margin: 0;
          font-size: 1.6rem;
        }
        .title{
          font-size: 2.2rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }
      }
      .icon-wrap{
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          width: 100%;
          stroke: white;
        }
      }
      &.green{
        background: $green;
      }
    }
  }
  .contact-split{
    padding: 0 2rem;
    @include md{
      display: flex;
      flex-direction: row-reverse;
    }
    .send-message{
      margin-bottom: 1rem;
      padding: 2rem 0;
      @include md{
        flex: 1;
        margin-left: 2rem;
      }
      p{
        font-size: 1.8rem;
        margin: 0;
        padding: 0;
      }
      form{
        label{
          display: none;
        }
        input[type=text], textarea, input[type=numeric]{
          background: #f5f5f5;
          color: #222;
          font-weight: 700;
          font-size: 2rem;
          border: 0;
          outline: 0;
          padding: 2rem;
          border-radius: 0.5rem;
          display: block;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 1rem;
        }
        textarea{
          min-height: 100px;
        }
        input[type=submit]{
          display: block;
          background: $dark-blue;
          color: white;
          border: 0;
          outline: 0;
          box-sizing: border-box;
          width: 100%;
          padding: 2rem;
          font-size: 2rem;
          border-radius: 0.5rem;
          font-weight: 700;
        }
      }
    }
  }
  @include lg{
    padding: 10rem $gutter-desktop;
    >h2{
      text-align: center;
      font-size: 3.5rem;
    }
    >p{
      text-align: center;
    }
    .contact-links{
      margin-top: 5rem;
    }
  }
}
