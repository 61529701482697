.cta{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: white;
  border: 0;
  outline: 0;
  height: 5rem;
  color: black;
  border-radius: 2.5rem;
  padding: 0 3rem;
  font-family: "basier", sans-serif;
  font-size: 1.8rem;
  box-sizing: border-box;
  transition: 0.1s ease-out;
  font-weight: 700;
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.15);
  i{
    margin-right: 0.5rem;
  }
  &.full-width{
    display: flex;
  }
  &:hover{
    transform: scale(1.025);
  }
}
