#post {
  figure.post-image{
    height: 100%;
    background-size: cover;
    background-position: center center;
    flex: 1;
    margin: 0;
  }
  p{
    font-size: 1.25rem;
  }
  .post-images{
    height: 350px;
    display: flex;
    @include sm{
      height: 600px;
    }
  }
  .title-subtitle{
    padding: 2rem;
    h1{
      text-align: left;
      margin: 0 0 0.5rem;
    }
    h2{
      text-align: left;
      margin: 0 0 0.5rem;
    }
  }
  .post-content{
    padding: 0 2rem 2rem;
    p{
      margin: 0;
      font-size: 1.6rem
    }
  }
}
