@font-face {
  font-family: 'basier';
  src: url('/assets/fonts/basiercircle-bold-webfont.woff2') format('woff2'), url('/assets/fonts/basiercircle-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'basier';
  src: url('/assets/fonts/basiercircle-regular-webfont.woff2') format('woff2'), url('/assets/fonts/basiercircle-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'basier';
  src: url('/assets/fonts/basiercircle-semibold-webfont.woff2') format('woff2'), url('/assets/fonts/basiercircle-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
