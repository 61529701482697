section#cover{
  height: 70%;
  background: url(/assets/images/cover.jpg);
  background-size: cover;
  background-position-y: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 15rem $gutter-mobile;
  h2{
    background: $logo-blue;
    color: white;
    font-weight: 700;
    font-size: 2.8rem;
    display: inline;
    margin: 10rem 0 0;
    line-height: 1.25;
    box-shadow: 1rem 0 0 $logo-blue, -1rem 0 0 $logo-blue;
    position: relative;
    left: 1rem;
  }
  h3{
    color: $primary;
    font-weight: 700;
    font-size: 2.3rem;
    margin: 2rem 0 4rem;
  }
  .cta{
    font-size: 2rem;
    color: black;
    height: 6rem;
    border-radius: 3rem;
  }
  @include md{
    padding: 20rem $gutter-desktop;
    .wrapper{
      text-align: center;
    }
    h2{
      font-size: 4rem;
      text-align: center;
    }
    h3{
      font-size: 3.5rem;
      text-align: center;
    }
  }
}

