section#services{
  background: white;
  overflow: auto;
  padding: $gutter-mobile $gutter-mobile 0;
  position: relative;
  overflow: visible;
  .wrapper{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .intro{
    margin-bottom: 5rem;
    h1{
      text-align: left;
      color: black;
    }
    >p{
      text-align: left;
      font-size: 1.8rem;
      font-weight: normal;
      line-height: 1.65;
    }
  }
  .services{
    display: flex;
    flex-direction: column;
    .service{
      background: white;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 10px 1px #eee;
      display: flex;
      margin-bottom: 1rem;
      .service-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        i{
          font-size: 4rem;
          color: $accent;
        }
      }
      .service-info{
        padding: 2rem 2rem 2rem 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h4{
          font-weight: 600;
          font-size: 2rem;
          margin: 0;
          margin-bottom: 1rem;
        }
        >p{
          font-weight: 400;
          font-size: 1.5rem;
          margin: 0;
        }
      }
    }
  }
  .google-review{
    margin-top: 5rem;
  }
  @include md{
    padding: 10rem $gutter-desktop 0;
    .wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .intro{
      flex: 1;
      margin-right: 3rem;
      margin-bottom: 0;
      max-width: 650px;
      h1{
        font-size: 3.5rem;
      }
      >p{
        max-width: 700px;
        font-size: 2rem;
      }
      >p:last-of-type{
        margin-bottom: 3rem;
      }
    }
    .services{
      flex: 1;
      max-width: 400px;
      .service{
        margin-bottom: 2rem;
        .service-info{
          h4{
            font-size: 2.15rem;
          }
          >p{
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}
