section#navbar{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10rem;
  z-index: 100;
  a.logo{
    margin: 0 0 0 $gutter-mobile;
    width: 25rem;
    background: url(/assets/images/robertkingram-logo.svg) center left no-repeat;
    background-size: 20rem;
    text-indent: -9999px;
    display: block;
    font-size: 4rem;
    @include md{
      margin: 0 0 0 $gutter-desktop;
    }
  }
  .hamburger-button{
    padding: 0 $gutter-mobile;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    a{
      color: $primary;
    }
    @include sm{
      position: absolute;
      top: 2rem;
      right: 0;
    }
    @include md{
      position: relative;
      top: 0;
      right: 0;
      display: none;
    }
  }
  .phone-number{
    display: none;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    width: 25rem;
    padding: 0 $gutter-desktop 0 0;
    @include md{
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;
    }
  }
  nav{
    flex: 1;
    height: 100%;
    display: none;
    @include md {
      display: block;
    }
    ul{
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      li{
        margin: 0 1.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          display: inline-block;
          padding: 0.25rem 0;
          font-size: 1.8rem;
          color: $primary;
          font-weight: 600;
          text-decoration: none;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
          transition: border-bottom-color 0.25s;
          &:hover{
            border-bottom-color: $primary;
          }
        }
      }
    }
  }
}

#fullscreen-menu{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $dark-blue;
  opacity: 0.95;
  z-index: 1000;
  .close-button{
    position: fixed;
    top: 0;
    right: 0;
    padding: 0 $gutter-mobile;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    i{
      color: white;
    }
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    li{
      text-align: center;
      padding: 2rem 0;
      margin: 0 3rem;
      &:last-child{
        border-bottom: 0;
      }
      a{
        color: white;
        text-decoration: none;
        font-size: 2rem;
        font-weight: bold;
      }
    }
  }
}
